import StartButton from './StartButton';
import Clock from './Clock';
import Menu from './Menu';
import github from '../images/github.png';
import linkedin from '../images/linkedin.png';

export default function BottomBar({
    isMenuOpen,
    setIsMenuOpen,
    isMiniMenuOpen,
    setMiniMenuOpen,
}) {
    return (
        <div id="bottomBar">
            {isMenuOpen && <Menu />}

            <StartButton
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                isMiniMenuOpen={isMiniMenuOpen}
                setMiniMenuOpen={setMiniMenuOpen}
            />
            <div id="rightSideBottomeBar">
                <a
                    href="https://www.linkedin.com/in/alexis-goutorbe"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="navBarLogo"
                        src={linkedin}
                        alt="linkedin logo"
                    />
                </a>
                <a
                    href="https://github.com/G-alexis-Fr"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="navBarLogo"
                        src={github}
                        alt="github logo"
                    />
                </a>
                <Clock />
            </div>
        </div>
    );
}
