import React from 'react';
import sportsUp from '../images/sportsup.png';
import team from '../images/sportsUp/team.png';
import premium from '../images/sportsUp/premium.png';
import eventsSugg from '../images/sportsUp/eventsSugg.png';
import profileUpd from '../images/sportsUp/profileUpd.png';

export default function SportsUp() {
    return (
        <>
            <h1>Sports Up</h1>
            <img className="logoProject" src={sportsUp} alt="Sports Up Logo" />

            <p>
                I have been leading a small team of 3 peoples with a 3/4 weeks
                project. <br />
                <br /> These persons took a 3 months intensive programming
                bootcamp starting with no background. We managed to build a
                platform where people could come to meet others around sports.
                <br />
                We got our idea from a very famous site, and adjust the size to
                be sure we could have something to present in front the dev team
                at the end of the project.
                <br />
                <br />
                We developed it using Html/Css/Javascript and Php.
            </p>
            <h1>Profile</h1>
            <p>
                Here is a small part of your profile dashboard where you can
                manage your personnal informations and also the events you may
                create or attend.
                <br />
                Creating an account can be done with Basic registration such as
                email/passowrd, but it can also be done with KakaoTalk (Korean
                most popular communication App)
            </p>
            <img src={profileUpd} alt="Profile Update" />
            <h1>Events</h1>
            <p>
                According to your sports interests and events you are joining we
                are suggesting others events you may like.
            </p>
            <img src={eventsSugg} alt="Events Suggestion" />
            <h1>Go Premium</h1>
            <p>
                By going premium this give you the opportunity to create events
                and make pay the attendees, as a none premium member you may
                only be able to create Free events.
            </p>
            <img src={premium} alt="Go Premium" />
            <h1>The Team</h1>
            <p>
                I had the chance to work with a good team of 3 peoples coming
                from all around the globe, 4 persons 4 nationalities. I'am glad
                that we have been able to build this site in this such short
                amount of time.
                <br />
                <br />
            </p>
            <img src={team} alt="Meet the team" />

            <h1>Visit our Website</h1>
            <p>
                Please visit our website by following this link
                <button
                    className="spaceLink"
                    onClick={() =>
                        window.open('https://sportsup.g-alexis.com', '_blank')
                    }
                >
                    Sports Up
                </button>
            </p>
        </>
    );
}
