import React from 'react';
import speaker from '../images/loudspeaker_rays-0.png';
import getTime from '../functions/getTime';

export default function Clock() {
    return (
        <button id="clock">
            <img src={speaker} alt="speaker" />
            <p>{getTime()}</p>
        </button>
    );
}
