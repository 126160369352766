import Draggable from 'react-draggable';
import Modal from './Modal.js';
import ModalError from './ModalError.js';
import error from '../images/error.png';
import React, { useState } from 'react';
import errorSound from '../sounds/error-sound.mp3';
import WebPage from './WebPage.js';

export default function Icons({
    folder,
    title,
    cssId,
    style,
    clickable,
    webPage,
    open = false,
}) {
    // This is for the the new Window
    const [isModalOpen, setIsModalOpen] = useState(open);
    // This is for the error Window
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    // This is for the right click
    const [isWebPOpen, setIsWebPOpen] = useState(false);

    const handleClick = (e, title) => {
        if (e.detail === 2 && clickable && title === 'InBox')
            window.open('mailto:contact@g-alexis.com?subject=Contact');

        if (e.detail === 2 && clickable && title === 'Age Of Empire 1') {
            const audio = new Audio(errorSound);
            audio.play();
            setIsErrorOpen(true);
        }

        if (
            e.detail === 2 &&
            clickable &&
            title !== 'Age Of Empire 1' &&
            title !== 'InBox'
        )
            setIsModalOpen(true);

        if (e.detail === 2 && webPage) {
            setIsWebPOpen(true);
        }
    };

    return (
        <>
            <Draggable>
                <div
                    id={cssId}
                    className="iconsComponent"
                    onClick={(e) => handleClick(e, title)}
                >
                    <img draggable={false} src={folder} alt={title} />
                    <p style={style}>{title}</p>
                </div>
            </Draggable>

            {isErrorOpen && (
                <ModalError
                    setIsErrorOpen={setIsErrorOpen}
                    title="Insert Disk"
                    message="Please insert the Age of Empire 1 disk 1, and then click OK."
                    icon={error}
                />
            )}

            {isModalOpen && (
                <Modal
                    cssId={cssId}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    title={title}
                />
            )}

            {isWebPOpen && (
                <WebPage
                    isWebPOpen={isWebPOpen}
                    setIsWebPOpen={setIsWebPOpen}
                    title={title}
                />
            )}
        </>
    );
}
