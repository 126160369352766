import React from 'react';
import startIcon from '../images/windows_slanted-1.png';

export default function StartButton({
    isMenuOpen,
    setIsMenuOpen,
    isMiniMenuOpen,
    setMiniMenuOpen,
}) {
    const handleClick = () => {
        document.getElementById('miniMenu').style = '';
        setIsMenuOpen(!isMenuOpen);
        if (isMiniMenuOpen) setMiniMenuOpen(false);
    };

    return (
        <button id="startButton" onClick={handleClick}>
            <img src={startIcon} alt="Menu" />
            <p>Start</p>
        </button>
    );
}
