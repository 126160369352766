import React from 'react';
import ShutDownScreen from './ShutDown';

export default function IconsMenu({
    icon,
    title,
    arrow,
    shutDown,
    setShutDown,
}) {
    const handleClick = (title) => {
        if (title === 'Shut Down..') {
            const body = document.querySelector('body');
            body.style.cursor = 'wait';

            setTimeout(() => {
                setShutDown(true);
                document.getElementById('desktop').remove();
            }, 3000);
        }
    };

    return (
        <>
            {shutDown && <ShutDownScreen />}

            <div id="MenuIcon" onClick={() => handleClick(title)}>
                <img src={icon} alt="icons menu" />
                <h2>{title}</h2>
                {arrow && <img id="arrowMenu" src={arrow} alt="arrow" />}
            </div>
        </>
    );
}
