import React from 'react';
import Icons from './Icons';
import directory from '../images/directory_closed_cool-0.png';
import myComputer from '../images/computer_explorer-5.png';
import trashBin from '../images/recycle_bin_empty-5.png';
import mailBox from '../images/mailbox_world-2.png';
import ageOfEmpire from '../images/ageOfEmpire.png';
import kodak from '../images/kodak.png';
import notepad from '../images/notepad.png';
import RightClickModal from './RightClickModal';

export default function Desktop({
    isMenuOpen,
    setIsMenuOpen,
    isMiniMenuOpen,
    setMiniMenuOpen,
}) {
    function handleClick(e) {
        document.getElementById('miniMenu').style = '';
        if (isMiniMenuOpen) setMiniMenuOpen(false);

        e.preventDefault();
        if (e.type === 'click' && isMenuOpen) {
            // Left Click
            setIsMenuOpen(false);
        } else if (e.type === 'contextmenu') {
            // This is the rigth click
            if (isMenuOpen) setIsMenuOpen(false);
            setMiniMenuOpen(true);

            let rigthClickM = document.getElementById('miniMenu');
            rigthClickM.style.position = 'absolute';
            rigthClickM.style.height = '333px';
            rigthClickM.style.width = '220px';
            rigthClickM.style.cursor = 'pointer';
            rigthClickM.style.borderTop = '4px solid #fff';
            rigthClickM.style.borderLeft = '4px solid #fff';
            rigthClickM.style.borderBottom = '4px solid #7e7e7e';
            rigthClickM.style.borderRight = '4px solid #7e7e7e';
            rigthClickM.style.backgroundColor = '#bebebe';
            rigthClickM.style.zIndex = 100;

            rigthClickM.style.left = `${e.clientX - 220}px`;
            rigthClickM.style.top = `${e.clientY - 333}px`;
        }
    }

    return (
        <div id="desktop" onClick={handleClick} onContextMenu={handleClick}>
            <Icons
                folder={myComputer}
                title="My Computer"
                cssId="myComputer"
                clickable={true}
            />
            <Icons
                folder={directory}
                title="My Projects"
                cssId="myProjects"
                clickable={true}
            />
            <Icons
                folder={trashBin}
                title="Recycle Bin"
                cssId="recycleBin"
                clickable={true}
            />
            <Icons
                folder={mailBox}
                title="InBox"
                cssId="inbox"
                clickable={true}
            />
            <Icons
                folder={ageOfEmpire}
                title="Age Of Empire 1"
                cssId="ageOfEmpire"
                clickable={true}
            />
            <Icons
                folder={kodak}
                title="myPicture.jpg"
                cssId="myPicture"
                clickable={true}
            />
            <Icons
                folder={notepad}
                title="ReadMe"
                cssId="readMe"
                clickable={true}
                open={true}
            />
            <div id="miniMenu">{isMiniMenuOpen && <RightClickModal />}</div>
        </div>
    );
}
