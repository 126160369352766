import BottomBar from '../components/BottomBar';
import Desktop from '../components/Desktop';
import React, { useState, useEffect } from 'react';
import startSound from '../sounds/start-sound.wav';

const App = () => {
    // This is for the menu at the left bottom
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // This is for the right click
    const [isMiniMenuOpen, setMiniMenuOpen] = useState(false);

    useEffect(() => {
        const audio = new Audio(startSound);
        audio.play();
    }, []);

    return (
        <div>
            <Desktop
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                isMiniMenuOpen={isMiniMenuOpen}
                setMiniMenuOpen={setMiniMenuOpen}
            />
            <BottomBar
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                isMiniMenuOpen={isMiniMenuOpen}
                setMiniMenuOpen={setMiniMenuOpen}
            />
        </div>
    );
};

export default App;
