import React from 'react';
import capcoreeapp from '../images/capcoreeApp.png';
import login from '../images/capcoreeApp/login.png';
import employe from '../images/capcoreeApp/employe.png';
import voyageurs from '../images/capcoreeApp/voyageurs.png';

export default function CapCoreeApp() {
    return (
        <>
            <h1>Cap Coree App</h1>
            <img
                className="logoProject"
                src={capcoreeapp}
                alt="Cap Coree Logo"
            />

            <p>
                Cap Coree is a travel agency located in Seoul in South Korea.{' '}
                <br />
                During my study i had to create 2 projects, for the first one i
                decided to do a program to manage the employees/travelers and
                the activities of the agency by making a Windows Application in
                C#.
                <br />
                <br />
            </p>
            <h1>Login</h1>
            <p>
                According to your role into the travel agency, you would have
                differents access to the menu.
                <br />
                The admin role, can create new user, the new user (the employee
                of the travel agency), will receive their credential by email.
            </p>
            <img src={login} alt="Login" />
            <h1>Main dashboard</h1>
            <p>
                Connected as an Admin, you can have access to everything.
                Create/Update/Delete any informations.
            </p>
            <img src={employe} alt="Main Dashboard" width={'90%'} />
            <h1>Travelers</h1>
            <p>
                Similar with the employees, the traveler's page as some extra
                informations, here we can prepare the entire travel, and even
                export the roadmap with the pricing to an excel sheet.
            </p>
            <img src={voyageurs} alt="Travelers" width={'90%'} />
        </>
    );
}
