import React from 'react';
import MiniIconsMenu from './MiniIconsMenu';

export default function RightClickModal() {
    return (
        <>
            <MiniIconsMenu title="Open" />
            <MiniIconsMenu title="Explore" />
            <MiniIconsMenu title="Find Computer" />
            <p className="line"></p>
            <MiniIconsMenu title="Map Network Drive..." />
            <MiniIconsMenu title="Disconnect Network Drive..." />
            <p className="line"></p>
            <MiniIconsMenu title="Create Shortcut" />
            <MiniIconsMenu title="Rename" />
            <p className="line"></p>
            <MiniIconsMenu title="Properties" />
        </>
    );
}
