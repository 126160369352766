import React, { useEffect } from 'react';
import { useState } from 'react';
import Draggable from 'react-draggable';
import CapCoreeApp from './CapCoreeApp';
import CapCoreeWeb from './CapCoreeWeb';
import Kadakudu from './Kadakudu';
import SportsUp from './SportsUp';
import Staritda from './Staritda';
import Portfolio from './Portfolio';

export default function WebPage({ isWebPOpen, setIsWebPOpen, title }) {
    const [url, setUrl] = useState();

    useEffect(() => {
        let baseUrl = 'http://g-alexis.com/portfolio/';

        if ('CapCoreeApp' === title) setUrl((baseUrl += 'CapCoreeApp'));
        if ('CapCoreeWeb' === title) setUrl((baseUrl += 'CapCoreeWeb'));
        if ('Kadakudu' === title) setUrl((baseUrl += 'Kadakudu'));
        if ('SportsUp' === title) setUrl((baseUrl += 'SportsUp'));
        if ('Staritda' === title) setUrl((baseUrl += 'Staritda'));
        if ('Portfolio' === title) setUrl((baseUrl += 'Portfolio'));
    }, [title]);

    return (
        <Draggable>
            <div id="webPageModal">
                <div id="topBarWindowModal">
                    <h4>WebPage - {title}</h4>
                    <div>
                        <button className="search"></button>
                        <button
                            className="close"
                            onClick={() => setIsWebPOpen(false)}
                        ></button>
                    </div>
                </div>

                <div>
                    <p>File</p>
                    <p>Edit</p>
                    <p>View</p>
                    <p>Help</p>
                </div>

                <div id="addressBar">
                    <p>Address:</p>
                    <input type="text" readOnly value={url} />
                </div>

                <div id="centerWebPageModal">
                    {title === 'CapCoreeApp' && <CapCoreeApp />}
                    {title === 'CapCoreeWeb' && <CapCoreeWeb />}
                    {title === 'Kadakudu' && <Kadakudu />}
                    {title === 'SportsUp' && <SportsUp />}
                    {title === 'Portfolio' && <Portfolio />}
                    {title === 'Staritda' && <Staritda />}
                </div>
            </div>
        </Draggable>
    );
}
