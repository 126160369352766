import React from 'react';
import Draggable from 'react-draggable';
import BottomModal from './BottomModal';
import Icons from './Icons';
import hardDisk from '../images/hard_disk_drive_cool-3.png';
import newHardDisk from '../images/cd_drive-3.png';
import controlPanel from '../images/directory_admin_tools-4.png';
import directoryDial from '../images/directory_dial.png';
import capcoreeApp from '../images/capcoreeApp.png';
import capcoreeWeb from '../images/capcoreeWeb.png';
import kadakudu from '../images/kadakudu.png';
import kodak from '../images/kodak.png';
import sportsUp from '../images/sportsup.png';
import portfolio from '../images/windows_slanted-1.png';
import staritda from '../images/staritda-logo.png';
import directory from '../images/directory_closed_cool-0.png';

export default function Modal({ cssId, setIsModalOpen, isModalOpen, title }) {
    const handleComputer = () => {
        return (
            <>
                <div className="centerModal">
                    <div className="fileInFolder">
                        <Icons
                            folder={hardDisk}
                            title="(C:)"
                            cssId="hardDisk"
                            style={{ color: 'black' }}
                            clickable={false}
                        />
                        <Icons
                            folder={newHardDisk}
                            title="New (D:)"
                            cssId="newHardDisk"
                            style={{ color: 'black' }}
                            clickable={false}
                        />
                        <Icons
                            folder={controlPanel}
                            title="Control panel"
                            cssId="controlPanel"
                            style={{ color: 'black' }}
                            clickable={false}
                        />
                        <Icons
                            folder={directoryDial}
                            title="Dial-Up Directory"
                            cssId="directoryDial"
                            style={{ color: 'black' }}
                            clickable={false}
                        />
                    </div>
                </div>
                <BottomModal nbObject="4" weight="67.00" />
            </>
        );
    };

    const handleRecycleBin = () => {
        return (
            <>
                <div className="centerModal">
                    <div className="fileInFolder">
                        <Icons
                            folder={kodak}
                            title="00145.jpg"
                            cssId="kodak"
                            style={{ color: 'black' }}
                            clickable={false}
                        />
                        <Icons
                            folder={kodak}
                            title="cats.jpg"
                            cssId="kodak"
                            style={{ color: 'black' }}
                            clickable={false}
                        />
                    </div>
                </div>
                <BottomModal nbObject="2" weight="96.00" />
            </>
        );
    };

    const handleMyProjects = () => {
        return (
            <>
                <div className="centerModal">
                    <div className="fileInFolder">
                        <Icons
                            folder={capcoreeApp}
                            title="CapCoreeApp"
                            cssId="projectPics"
                            style={{ color: 'black' }}
                            clickable={false}
                            webPage={true}
                        />

                        <Icons
                            folder={kadakudu}
                            title="Kadakudu"
                            cssId="projectPics"
                            style={{ color: 'black' }}
                            clickable={false}
                            webPage={true}
                        />
                        <Icons
                            folder={sportsUp}
                            title="SportsUp"
                            cssId="projectPics"
                            style={{ color: 'black' }}
                            clickable={false}
                            webPage={true}
                        />
                        <Icons
                            folder={directory}
                            title="inProgress"
                            cssId="inProgress"
                            style={{ color: 'black' }}
                            clickable={true}
                        />
                    </div>
                </div>
                <BottomModal nbObject="4" weight="156.00" />
            </>
        );
    };
    const handleProInProgress = () => {
        return (
            <>
                <div className="centerModal">
                    <div className="fileInFolder">
                        <Icons
                            folder={capcoreeWeb}
                            title="CapCoreeWeb"
                            cssId="projectPics"
                            style={{ color: 'black' }}
                            clickable={false}
                            webPage={false}
                        />
                        <Icons
                            folder={portfolio}
                            title="Portfolio"
                            cssId="projectPics"
                            style={{ color: 'black' }}
                            clickable={false}
                            webPage={false}
                        />
                        <Icons
                            folder={staritda}
                            title="Staritda"
                            cssId="projectPics"
                            style={{ color: 'black' }}
                            clickable={false}
                            webPage={false}
                        />
                    </div>
                </div>
                <BottomModal nbObject="3" weight="122.00" />
            </>
        );
    };

    const handleMyPicture = () => {
        return (
            <>
                <div className="centerModal">
                    <div id="myPictureInner"></div>
                </div>
                <BottomModal nbObject="1" weight="19.00" />
            </>
        );
    };
    const handleReadMe = () => {
        return (
            <>
                <div id="readMeModal" className="centerModal">
                    <h1>Read Me</h1>
                    <p>
                        <b>Welcome</b> to my portfolio, <br />
                        <br />
                        Feel free to navigate into folders to find out what i
                        have been working on, the technology i have been using.
                    </p>
                    <p>
                        You can contact me directly with the mailbox on the
                        desktop or via other platform by clicking on the icons
                        in the navBar.
                    </p>
                    <p>
                        Remember to use me like your computer, with 1 click to
                        drag and drop, 2 clicks to open a folder. Not every
                        folder are openable.
                    </p>
                    <p>ps : This is an ongoing project</p>
                </div>
                <BottomModal nbObject="none" weight="37.00" />
            </>
        );
    };

    return (
        <Draggable>
            <div id="windowModal">
                <div id="topBarWindowModal">
                    <h4>{title}</h4>
                    <div>
                        <button className="search"></button>
                        <button
                            className="close"
                            onClick={() => setIsModalOpen(false)}
                        ></button>
                    </div>
                </div>

                <div>
                    <p>File</p>
                    <p>Edit</p>
                    <p>View</p>
                    <p>Help</p>
                </div>

                {cssId === 'myComputer' && handleComputer()}
                {cssId === 'recycleBin' && handleRecycleBin()}
                {cssId === 'myProjects' && handleMyProjects()}
                {cssId === 'myPicture' && handleMyPicture()}
                {cssId === 'inProgress' && handleProInProgress()}
                {cssId === 'readMe' && handleReadMe()}
            </div>
        </Draggable>
    );
}
