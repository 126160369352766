const getTime = () => {
    let time = new Date();
    let hour = time.getHours();
    let minut = time.getMinutes();

    hour = hour > 12 ? hour - 12 : hour;
    minut = minut > 9 ? minut : `0${minut}`;
    let amPm = hour < 12 ? 'PM' : 'AM';

    return `${hour}:${minut} ${amPm}`;
};

export default getTime;
