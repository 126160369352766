import Draggable from 'react-draggable';

export default function ModalError({
    setIsErrorOpen,
    isErrorOpen,
    title,
    message,
    icon,
}) {
    return (
        <Draggable>
            <div id="windowModalError">
                <div id="topBarWindowModal">
                    <h4>{title}</h4>
                    <div>
                        <button className="search"></button>
                        <button
                            className="close"
                            onClick={() => setIsErrorOpen(false)}
                        ></button>
                    </div>
                </div>
                <div className="errorMessage">
                    <img className="errorImg" src={icon} alt="error" />
                    <p>{message}</p>
                </div>
                <button
                    className="buttonOk"
                    onClick={() => setIsErrorOpen(false)}
                >
                    OK
                </button>
            </div>
        </Draggable>
    );
}
