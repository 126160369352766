import React, { useState } from 'react';
import IconsMenu from './IconsMenu';
import programs from '../images/programs.png';
import documents from '../images/documents.png';
import settings from '../images/settings.png';
import search from '../images/search.png';
import help from '../images/help_book.png';
import run from '../images/run.png';
import shut_down from '../images/shut_down.png';
import arrow from '../images/arrow.png';

export default function Menu() {
    const [shutDown, setShutDown] = useState(false);
    return (
        <>
            <div id="Menu">
                <IconsMenu icon={programs} title="Programs" arrow={arrow} />
                <IconsMenu icon={documents} title="Documents" arrow={arrow} />
                <IconsMenu icon={settings} title="Settings" arrow={arrow} />
                <IconsMenu icon={search} title="Find" arrow={arrow} />
                <IconsMenu icon={help} title="Help" />
                <IconsMenu icon={run} title="Run" />
                <p className="line"></p>
                <IconsMenu
                    icon={shut_down}
                    title="Shut Down.."
                    shutDown={shutDown}
                    setShutDown={setShutDown}
                />
            </div>
        </>
    );
}
