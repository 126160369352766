import React from 'react';

export default function BottomModal({ nbObject, weight }) {
    return (
        <div id="bottomModal">
            { nbObject === 'none' ? <div></div> : <div>{nbObject} Object(s)</div>}
            <div>{weight} KB</div>
        </div>
    );
}
