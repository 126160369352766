import React from 'react';
import kadakudu from '../images/kadakudu.png';

export default function Kadakudu() {
    return (
        <>
            <h1>Kadakudu</h1>
            <img className="logoProject" src={kadakudu} alt="Kadakudu Logo" />

            <p>
                Kadakudu is an online selling shoes company. <br />
                They wanted a solution for their customers to take a picture of
                their foot with a smartphone and get the measurement of it in
                result.
                <br />
                <br />
                We used the library <b>OpenCv</b> to do the contours detections
                with the language <b>Python</b>. And implemented everything into
                a shopify website.
                <br />
                <br />I was in charge of the detections and measurements.
            </p>
            <img
                src="https://cdn.shopify.com/s/files/1/0259/2833/5396/files/How_to_use_short_ver.gif?v=1615449823"
                alt="Gif Project"
            />

            <h1>Visit Kadakudu Website</h1>
            <p>
                Visit their website by following this link
                <button
                    className="spaceLink"
                    onClick={() =>
                        window.open(
                            'https://kadakudu.com/pages/size-me',
                            '_blank',
                        )
                    }
                >
                    Kadakudu
                </button>
            </p>
        </>
    );
}
